import React from 'react';
import BaseLayoutTemplate from '../components/environments/BaseLayoutTemplate';
import styled from 'styled-components';
import PageTitle from '../components/atoms/PageTitle';
import HeadBase from '../components/molecules/HeadBase';
import { CROCCHA_API, MetaDesc, MetaOGP, Tel } from '../utils';

const CommercialTransactionsPage = () => {
  return (
    <BaseLayoutTemplate>
      <HeadBase
        metas={{
          title: '特定商取引法に基づく表示 | tryangle株式会社',
          description: MetaDesc,
          openGraph: {
            type: 'article',
            url: `${CROCCHA_API.SELF_DOMAIN}/commercial-transactions/`,
            title: '特定商取引法に基づく表示 | tryangle株式会社',
            description: MetaDesc,
            images: [
              {
                url: MetaOGP,
                width: 1200,
                height: 630,
                alt: 'tryangle株式会社',
              },
            ],
            site_name: 'tryangle株式会社',
          },
        }}
      />

      {/* <!-- COMMERCIAL TRANSACTIONS --> */}
      <PageTitle title='特定商取引法に基づく表示' span='' />
      <Section>
        <dl>
          <HeadingDt>
            <h4>販売業者</h4>
          </HeadingDt>
          <dd>
            <InnerDl>
              <dt>
                <span>名称</span>
              </dt>
              <dd>
                <span>croccha shop</span>
              </dd>
            </InnerDl>
            <InnerDl>
              <dt>
                <span>代表者</span>
              </dt>
              <dd>
                <span>tryangle株式会社</span>
              </dd>
            </InnerDl>
            <InnerDl>
              <dt>
                <span>サイト運営責任者</span>
              </dt>
              <dd>
                <span>藤原　真吾</span>
              </dd>
            </InnerDl>
            <InnerDl>
              <dt>
                <span>所在地</span>
              </dt>
              <dd>
                <span>
                  579-8047
                  <br />
                  大阪府東大阪市桜町2-3 日興桜町ビル4F
                </span>
              </dd>
            </InnerDl>
            <InnerDl>
              <dt>
                <span>お問い合わせ窓口</span>
              </dt>
              <dd>
                <span>
                  { Tel }
                  <br />
                  shop@croccha.jp
                </span>
              </dd>
            </InnerDl>
            <InnerDl>
              <dt>
                <span>ホームページ</span>
              </dt>
              <dd>
                <span>https://try.croccha.com/</span>
              </dd>
            </InnerDl>
          </dd>
          <HeadingDt>
            <h4>商品の価格</h4>
          </HeadingDt>
          <dd>各商品ごとに記載</dd>
          <HeadingDt>
            <h4>商品代金以外のお支払い金額</h4>
          </HeadingDt>
          <dd>
            ● 宅急便：送料 540円（北海道・沖縄・離島除く）
            <br />
            ● メール便：送料 270円（全国一律）
            <br />
            <br />
            北海道・沖縄・離島の場合は以下のいずれかとなります
            <br />
            - レターパック：510円または360円
            <br />
            - 定形外郵便による実費
            <br />
            - 宅急便：1800円
            <br />
          </dd>
          <HeadingDt>
            <h4>お支払い方法</h4>
          </HeadingDt>
          <dd>クレジットカード払い、銀行振込</dd>
          <HeadingDt>
            <h4>お支払い期限</h4>
          </HeadingDt>
          <dd>
            ● クレジットカード：各クレジットカード会社の会員規約に基づくお支払い
            <br />● 銀行振込：10日以内にお振込みください
          </dd>
          <HeadingDt>
            <h4>引渡し時期</h4>
          </HeadingDt>
          <dd>ご注文を受けてから10日以内に発送いたします</dd>
          <HeadingDt>
            <h4>返品・交換</h4>
          </HeadingDt>
          <dd>
            ●
            お客様のご都合による返品は、8日以内にご相談下さい。（未開封品に限ります。）
            <br />※ この場合、送料はお客様のご負担となります。
          </dd>
          <HeadingDt>
            <h4>資格・免許</h4>
          </HeadingDt>
          <dd>・古物商許可証　第621020180338号　大阪府公安委員会</dd>
        </dl>
      </Section>
    </BaseLayoutTemplate>
  );
};

export default CommercialTransactionsPage;

const Section = styled.section`
  max-width: 800px;
  padding: 2rem 1rem;
  font-size: 14px;
  line-height: 1.6;
  color: #444;
  h4 {
    font-size: 100%;
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  > dl {
    border: 1px solid #e8e8e8;
    > dd {
      padding: 6px;
      &:first-of-type {
        padding: 0;
      }
    }
  }
`;

const HeadingDt = styled.dt`
  background-color: #e8e8e8;
  padding: 6px;
`;

const InnerDl = styled.dl`
  border-bottom: 1px solid #e8e8e8;
  > dt {
    background: #f9f9f9;
    border-bottom: 1px solid #e8e8e8;
    box-sizing: border-box;
    width: 100%;
  }
  > dl {
    border-left: none;
    width: 98%;
  }
  > dt,
  > dd {
    padding: 6px;
    display: inline-block;
    vertical-align: middle;
  }
`;
